.imsp-admin-section {
    align-items: flex-start;
    justify-content: center;
}

.settings-page {
    .checkbox-control {
        margin: auto;
    }
}
