// @import "variables";
// @import "mixins";

@mixin margin-between-items($margin){
	margin: 0 $margin;

	&:first-child{
		margin-left: 0;
	}

	&:last-child{
		margin-right: 0;
	}
}

.modal{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11100;
	background-color: rgba(#000, 0.25);
	display: none;
	padding: 10px;
	overflow: auto;

	&.show{
		display: block; // Musi zde byt kvuli problemum s Flexem na nekterych webkit browserech
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		&.align-to-top{
			align-items: flex-start;
		}
	}

	.modal-window{
		width: 550px;
		position: relative;
		margin: 0;
		padding: 0;

		background-color: #fff;
		box-shadow: 0px 2px 15px rgba(0,0,0,0.5);
		border-radius: 4px;

		.form{
			margin: 0;
			padding: 0;
		}

		.modal-window-header{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 0 0 0 20px;

			h4{
				padding: 0;
				cursor: default;
				font-size: 20px;
				line-height: 30px;
				margin: 20px 0 0 0;
				font-weight: bold;
				color: #595959;
			}

			.close-button{
				background-color: transparent;
				border: none;
				height: 40px;
				font-size: 20px;
				font-weight: bold;
				color: #4d4d4d;
				cursor: pointer;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				margin: 20px/2 20px/2 0 0;

				&:focus{
					outline: none;
				}
			}

		}

		.modal-window-content{
			overflow: visible;
			padding: 20px;

			// @include breakpoint-height-down(470px) {
			// 	flex: 1 0 auto;
			// }
		}

		.modal-window-footer{
			height: 60px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			padding: 0 20px;

			button{
				@include margin-between-items(6px);
			}

		}

		// @include breakpoint-down($breakpointSm) {
		// 	width: 100%;
		// }

	}
}
