.page-info {
  justify-content: center;
  padding: 20px;
  background: #ffeeee;
  border: solid 1px #4d4d4d;
  border-radius: 10px;
  margin: 2px;
  position: relative;

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    margin: 2px;
    width: 24px;
    text-align: center;
    color: #4d4d4d;

    &:hover {
      color: black;
    }
  }
}

.page-container.lunch-menu-container {
  flex-direction: column;

  .page-menu {
    width: auto;
  }

  .lunch-menu-part {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
  }

  .lunch-menu-head {
    .fill {
      flex: 1 1 auto;
    }

    .lunch-menu-info {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      padding: 10px;

      .row {
        padding: 0;
        margin: 0;

        .label {
          flex: 1 1 auto;
        }

        .value {
          width: 100px;
          text-align: right;
        }

        &.balance {
          font-weight: bold;
        }
      }

      button {
        font-size: 12px;
      }
    }
  }

  .lunch-table {
    overflow: auto;
    // padding: 20px;

    & > table {
      width: 100%;
      border-radius: 2px;
      table-layout: fixed;

      .date-col {
        width: 100px;
      }

      thead {
        background: #d9d9d9;

        th {
          border-right: solid 1px white;

          .menu-header {
            display: flex;
            flex-wrap: wrap;

            .menu-header-title {
              padding: 5px;
              flex: 1 1 auto;
            }

            .menu-header-check {
              flex: 0 0 auto;
              width: 34px;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                background: #00000011;
              }
            }
          }
        }
      }

      tbody {
        background: #f2f2f2;

        tr {
          &:nth-child(odd) {
            background: #fbfbfb;
          }

          td {
            position: relative;
            border-right: solid 1px white;

            &:last-child {
              border-right: none;
            }

            .menu-back.ordered {
              background: #fff2cc;
            }

            .menu-cell .text-part {
              color: #8c8c8c;
            }

            &.active {
              .menu-cell .text-part {
                color: #000000;
              }

              .menu-cell:hover {
                background: #00000011;
                cursor: pointer;
              }

              .menu-back {
                &.meal-1.ordered {
                  background: #ffcccc;
                }
                &.meal-2.ordered {
                  background: #ffcccc;
                }
                &.meal-3.ordered {
                  background: #ffcccc;
                }
                &.meal-4.ordered {
                  background: #b3e6b3;
                }
              }
            }
          }

          .date-cell {
            display: flex;
            flex-direction: column;
            padding: 3px 5px 1px 5px;

            .date-part {
              flex: 1 1 auto;
              display: flex;

              .day {
                flex: 0 0 auto;
              }

              .date {
                flex: 1 1 auto;
                text-align: right;
              }
            }
          }

          .menu-back {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0;
            border-radius: 5px;
            background: transparent;
          }

          .menu-cell {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 3px 5px 1px 5px;
            border-radius: 5px;
          }

          .ghost-content {
            display: flex;
            color: transparent;
            padding: 2px 4px 0px 4px;

            .check-part {
              width: 33px;
              flex: 0 0 33px;
            }
          }

          .text-part {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          }

          .menu-part {
            flex: 1 1 auto;
            display: flex;
            align-items: flex-start;
          }

          .check-part {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            padding: 10px;
          }

          .alg-part {
            flex: 0 0 auto;
            font-size: 10px;
          }
        }
      }
    }
  }

  .payment-info-modal {
    .payment-info-modal-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .payment-info {
        display: flex;
        align-items: center;

        .text-part {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: stretch;

          .payment-row {
            flex: 0 0 auto;
            display: flex;
            align-items: center;

            .label {
              flex: 0 0 auto;
              width: 160px;
            }

            .value {
              flex: 1 1 auto;
            }

            &.new-number .value {
              color: red;
            }
          }

          .new-number-info {
            color: red;
            font-size: 12px;
            margin-bottom: 10px;
          }
        }

        .qr-part {
          img {
            width: 160px;
            height: 160px;
          }
        }
      }

      .note {
        margin: 20px 0 0 0;
      }

      .buttons-row {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        padding-top: 10px;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .lunch-menu-part {
      flex-direction: column;
    }

    .lunch-menu-head {
      .lunch-menu-info {
        padding: 5px 5px 0 5px;
      }

      .buttons {
        display: none;
      }
    }

    .lunch-table {
      & > table {
        word-wrap: break-word;
        word-break: break-word;
        font-size: 12px;

        .date-col {
          width: 55px;
        }

        tbody {
          tr {
            .date-cell {
              .date-part {
                flex-direction: column;

                .day,
                .date {
                  text-align: left;
                }
              }
            }

            .ghost-content {
              flex-direction: column;
            }

            .check-part {
              display: none;
            }

            .menu-cell {
              flex-direction: column;
            }

            .menu-part {
              display: block;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 550px) {
    .payment-info-modal {
      .payment-info-modal-content {
        .payment-info {
          flex-direction: column;
          align-items: stretch;

          .text-part {
            .payment-row {
              flex-wrap: wrap;

              .value {
                padding-left: 20px;
                width: 180px;
              }
            }
          }

          .qr-part {
            text-align: center;
          }
        }
      }
    }
  }
}
