.payment-page {
    .page-menu {
        .info {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 10px;

            .item {
                display: flex;
                align-items: center;

                .label {
                    flex: 1 1 auto;
                }
                
                .value {
                    width: 100px;
                    text-align: right;
                }

                &.balance {
                    font-weight: bold;
                }
            }
        }
    }

    .page-content {
        .history-table {
            .col-time, .col-type, .col-amount {
                width: 100px;
            }

            tbody {
                tr {
                    &.payment {
                        background: #ffdddd;

                        &:nth-child(odd) {
                            background: #ffeeee;
                        }

                        &.selected {
                            background: #990000;
                            color: white;
                        }
                    }

                    &.deposite {
                        background: #ddffdd;

                        &:nth-child(odd) {
                            background: #eeffee;
                        }

                        &.selected {
                            background: #009900;
                            color: white;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1023px) {
        .page-content {
            table.history-table {
                word-wrap: break-word;
                word-break: break-word;
                font-size: 12px;

                .col-time, .col-type, .col-amount {
                    width: auto;
                }
            }
        }
    }
}

.history-container {
    display: flex;
    align-items: stretch;

    .menu-container {
        flex: 0 0 auto;

        .lunch-menu-filter {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            padding: 10px;

            .lunch-menu-period {
                display: flex;
                align-items: stretch;
                // justify-content: center;
                padding: 2px;
        
                & > * {
                    background-color: #f2f2f2;
                }
        
                & > .label {
                    flex: 1 0 auto;
                    width: 160px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 5px;
                }
        
                & > .btn {
                    flex: 0 0 auto;
                    width: 50px;
                    cursor: pointer;
        
                    &:hover {
                        background-color: #e6e6e6;
                    }
                }
            }

            .diner-selector-container {
                padding: 2px;

                .diner-selector {
                }
            }
        }
    }

    .data-container {
        flex: 1 1 auto;
    }
}