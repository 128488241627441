.month-order-view-page {
    .col-diner {
        width: 140px;
    }

    .col-group {
        width: 120px;
    }

    td.order {
        font-weight: bold;
    }

    &.page-container .page-menu {
        flex: 0 0 260px;
    }
}

.monthly-print {
    .header {
        display: flex;
        padding-bottom: 10px;

        .title {
            flex: 1 1 auto;
        }

        .filter {
            flex: 0 0 auto;
            display: flex;

            .period, .group {
                display: flex;

                .label {
                    width: 80px;
                }

                .value {
                    width: 140px;
                }
            }
        }
    }

    table {
        table-layout: fixed;
        width: 100%;

        .col-diner {
            width: 160px;
        }

        .col-group {
            width: 100px;
        }

        td {
            border:1px solid black;
            padding: 4px;
            word-wrap: break-word;
            white-space: normal;
            font-size: 14px;
        }
    
        th {
            border:1px solid black;
            padding: 4px;
        }
    }
}