.imsp-admin-section {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    // padding: 5px;

    .admin-menu {
        flex: 0 0 140px;
        padding: 5px;
        background: #ffe6e6;

        & > ul {
            margin: 0;
            padding: 0;
        }

        .link {
            background: #cc2031;
            list-style-type: none;
            padding: 5px;
            border-radius: 2px;
            margin: 0 0 4px 0;

            &:hover {
                background-color: #a01926;
            }

            &.selected {
                background-color: #00cc00;

                &:hover {
                    background-color: #00b300;
                }
            }
        }
    }
}