.page-container.notice-page {
    .col-time {
        width: 150px;
    }

    .page-container {
        flex-direction: column;

        .detail-part {
            margin-top: 40px;
        }
    }
}