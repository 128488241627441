.container-fluid {
    padding: 0;
}

.link {
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: #a01926;
        cursor: pointer;
        text-decoration: none;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #007bff;
    text-decoration: none;
    font-size: 12px;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}
  
.btn-imsp {
    color: #fff;
    background-color: #cc2031;
    border-color: #cc2031;

    &:hover {
        color: #fff;
        background-color: #b11c2a;
        border-color: #cc2031;
    }

    &.disabled:hover {
        color: #fff;
        background-color: #cc2031;
        border-color: #cc2031;
    }
}

.import-btn {
    position: relative;
    overflow: hidden;

    & > input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
    }
}

.app-page-container {
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;

    &.print {
        justify-content: flex-start;
    }
}

.page-container {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    max-width: 1920px;

    .page-menu {
        flex: 0 0 auto;
        width: 220px;
        display: flex;
        flex-direction: column;
        // align-items: stretch;

        & > *:not(:first-child) {
            margin-top: 10px;
        }

        .filter {
            & > *:not(:first-child) {
                margin-top: 5px;
            }

            .filter-group {
                padding: 0 5px;

                label {
                    margin: 0;
                    font-size: 14px;
                }
            }

            .check-filter {
                padding: 0 5px;
                display: flex;
                align-items: center;
                cursor: pointer;
                
                .label {
                    flex: 1 1 auto;
                    margin: 0;
                    padding: 5px 0;
                }

                input {
                    flex: 0 0 auto;
                    width: 24px;
                }

                &:hover {
                    .label {
                        // font-weight: bold;
                        // color: #000088;
                    }

                    // background: #00000011;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 5px;

            & > *:not(:first-child) {
                margin: 5px 0 0 0;
            }
        }

        .delimiter {
            height: 2px;
            background: #a6a6a6;
            margin: 15px 5px 0 5px;
        }
    }

    .page-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 5px;

        & > *:not(:first-child) {
            margin-top: 20px;
        }
    }

    .imsp-table {
        width: 100%;
        border-radius: 2px;
        table-layout: fixed;
    
        thead {
            background: #d9d9d9;
            
            th {
                padding: 5px;
            }
        }
    
        tbody {
            background: #f2f2f2;
    
            tr {
                overflow-wrap: break-word;
                
                &:nth-child(odd) {
                    background: #fbfbfb;
                }
    
                td {
                    position: relative;
                    border-right: solid 1px white;
                    padding: 2px 5px;
    
                    &:last-child {
                        border-right: none;
                    }
                }
    
                &.selected {
                    background: #ffe6e6;
    
                    &:nth-child(odd) {
                        background: #ffcccc;
                    }
                }
    
                &:hover > td {
                    background: #00000011;
                }
            }
        }
    }

    .common-text {
        font-size: 14px;
    }

    @media only screen and (max-width: 1023px) {
        flex-direction: column;

        .page-menu {
            & > *:not(:first-child) {
                margin-top: 0;
            }

            .filter {
                & > *:not(:first-child) {
                    margin-top: 0;
                }

                .filter-group {
                    padding: 2px 2px 0 2px;

                    label {
                        display: none;
                    }
                }
            }

            .delimiter {
                display: none;
            }
        }
    }
}

.ta-r {
    text-align: right;
}

.ta-c {
    text-align: center;
}

.settings-page {
    display: flex;
    justify-content: center;

    .settings-page-content {
        flex: 0 1 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .section {
            display: flex;
            flex-direction: column;
            padding: 20px;

            .section-title {
                font-size: 24px;
                border-bottom: solid 2px;
            }

            .section-content {
                padding: 10px 5px 5px 5px;

                .item-group {
                    display: flex;
                    align-items: center;

                    .label {
                        flex: 0 0 auto;
                        width: 140px;
                        padding: 7px;
                    }

                    .value {
                        flex: 1 1 auto;
                        min-width: 200px;
                        max-width: 400px;
                        padding: 5px;
                        word-wrap: break-word;
                    }
                }

                .buttons {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 10px;
                }
            }
        }

        @media only screen and (max-width: 779px) {
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .section {
                flex: 1 1 auto;
            }
        }

        @media only screen and (max-width: 449px) {
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .section {
                flex: 1 1 auto;
                padding: 5px;

                .section-content {
                    .item-group {
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
    
                        .label {
                            flex: 1 1 auto;
                            width: auto;
                            padding: 5px;
                        }
    
                        .value {
                            flex: 1 1 auto;
                            width: auto;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}

.buttons {
    display: flex;

    & > *:not(:first-child) {
        margin: 0 0 0 5px;
    }
}

.buttons-r {
    display: flex;
    flex-flow: row-reverse;

    & > *:not(:first-child) {
        margin: 0 5px 0 0;
    }
}