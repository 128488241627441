.page-container.emails {
    justify-content: center;

    .email-definition {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 20px;
        max-width: 800px;

        .to-part {
            display: flex;
            align-items: flex-start;
            padding: 5px;

            .to-view {
                flex: 1 1 auto;
                flex-wrap: wrap;
                display: flex;
                border: solid 1px #bbbbbb;
                border-radius: 5px;
                min-height: 38px;
                align-items: center;
    
                .placeholder {
                    color: #aaaaaa;
                    padding: 5px;
                }

                .receiver {
                    display: flex;
                    padding: 5px;
    
                    .type {
                        width: 30px;
                        text-align: center
                    }
                }
            }

            .btn {
                flex: 0 0 auto;
                margin-left: 10px;
                border: solid 1px #bbbbbb;
                border-radius: 5px;
                align-self: flex-end;

                &:hover {
                    background: #00000011;
                }
            }
        }

        .subject-part {
            padding: 5px;
            display: flex;
        }

        .message-part {
            padding: 5px;
            display: flex;
        }
    
        .button-part {
            flex: 1 1 auto;
            display: flex;
            flex-flow: row-reverse;
            padding: 5px;

            & > * {
                margin-left: 10px;
            }
        }

        .label {
            flex: 0 0 auto;
            width: 120px;
            padding: 5px;
        }
    }

    .select-to-modal {
        .receivers {
            border: solid 1px #bbbbbb;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            height: 200px;
            overflow: auto;

            .receiver {
                display: flex;
                padding: 2px;
                cursor: pointer;

                &.selected {
                    background: #ffcccc;
                }

                .type {
                    width: 30px;
                    text-align: center
                }

                &:hover {
                    background: #00000011;

                    &.selected {
                        background: #ffb3b3;
                    }
                }
            }
        }

        .buttons {
            display: flex;
            flex-flow: row-reverse;

            button {
                margin: 0 0 0 10px;
            }
        }
    }
}
