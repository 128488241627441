.row-cont {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.row-cont-right {
    display: flex;
    flex-direction: row;
    flex-flow: row-reverse;
    align-items: stretch;
}

.fill {
    flex: 1 1 auto;
}

.fit {
    flex: 0 0 auto;
}