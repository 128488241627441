.period {
    display: flex;
    align-items: stretch;
    
    & > * {
        background-color: #f2f2f2;
    }

    & > .label {
        flex: 1 1 auto;
        width: 160px;
        display: flex;
        align-items: stretch;
        justify-content: center;
        // padding: 0 5px;

        & > .label-item {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 3px;
        }
    }

    & > .btn {
        flex: 0 0 auto;
        width: 40px;
        cursor: pointer;

        &:hover {
            background-color: #e6e6e6;
        }

        &.disabled {
            cursor: default;
            color: #a6a6a6;
            opacity: 1;

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }
}