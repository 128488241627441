.import-result-container {
    .import-result-item {
        display: flex;
        align-items: center;
        padding: 2px 10px;

        .label {
            width: 60px;
        }

        .description {
            flex: 1 1 auto;
        }

        &.success {
            background: #ccffcc;
        }
        &.warning {
            background: #ffff99;
        }
        &.error {
            background: #ffb3b3;
        }
        &.info {
            background: #cce6ff;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row-reverse;
        padding-top: 20px;
    }
}