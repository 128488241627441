.diner-page {
    .filter {
        width: 220px;
    }

    .col-isaccount, .col-free, .col-balance, .col-glutenfree {
        width: 100px;
    }

    .col-paymentid, .col-unposted {
        width: 110px;
    }
}
