.link-button {
    text-align: right;
}

.menu-table {
    width: 100%;
    border-radius: 2px;
    table-layout: fixed;

    .col-date {
        width: 90px;
    }

    .col-lang {
        width: 80px;
    }

    thead {
        background: #d9d9d9;
        
        th {
            padding: 5px;
        }
    }

    tbody {
        background: #f2f2f2;

        tr {
            &:nth-child(odd) {
                background: #fbfbfb;
            }

            td {
                position: relative;
                border-right: solid 1px white;
                padding: 2px 5px;

                &:last-child {
                    border-right: none;
                }
            }

            &.selected {
                background: #ffe6e6;

                &:nth-child(odd) {
                    background: #ffcccc;
                }
            }

            &:hover > td {
                background: #00000011;
            }
        }
    }

    .cell-lang {
        text-align: center;
    }

    .date-container {
        display: flex;

        .date-day {
            flex: 0 0 auto;
        }

        .date-date {
            flex: 1 1 auto;
            text-align: right;
        }
    }
}
