.diner-order-view-page {
    .col-diner, .col-group {
        width: 26%;
    }

    .col-menu1, .col-menu2, .col-menu3, .col-snack {
        width: 12%;
    }

    i.fa-times {
        color: red;
    }

    i.fa-check {
        color: green;
    }
}