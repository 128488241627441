.profile-page {
    display: flex;
    justify-content: center;

    .profile-page-content {
        flex: 0 1 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .section {
            display: flex;
            flex-direction: column;
            padding: 20px;

            .section-title {
                font-size: 24px;
                border-bottom: solid 2px;
            }

            .section-content {
                padding: 10px 5px 5px 5px;

                .item-group {
                    display: flex;

                    .label {
                        flex: 0 0 auto;
                        width: 140px;
                        padding: 5px;
                    }

                    .value {
                        flex: 1 1 auto;
                        width: 200px;
                        padding: 5px;
                    }
                }

                .buttons {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 10px;
                }
            }
        }

        @media only screen and (max-width: 779px) {
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .section {
                flex: 1 1 auto;
            }
        }

        @media only screen and (max-width: 449px) {
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .section {
                flex: 1 1 auto;
                padding: 5px;

                .section-content {
                    .item-group {
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
    
                        .label {
                            flex: 1 1 auto;
                            width: auto;
                            padding: 5px;
                        }
    
                        .value {
                            flex: 1 1 auto;
                            width: auto;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}